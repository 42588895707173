import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { addPatientProfile } from "../../Thunk/patientThunks";
import { useEffect } from "react";
import toast from "react-hot-toast";
import ChildrenSection from "../PersonalSections/ChildrenSection";
import AccordionHeader from "../PersonalSections/AccordianSummaryHeader/AccordionHeader";
import ParentsSection from "../PersonalSections/ParentsSection";
const PersonalForm = ({ disabledFields, isEditable }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { msg, error, patientData } = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    values: {
      Name_Surname: user.Name,
      mail: user.email,
      DOB: patientData.DOB,
      Residence: patientData.Residence,
      Domicile: patientData.Domicile,
      Mobilephone: patientData.Mobilephone,
      Qualification: patientData.Qualification,
      Profession: patientData.Profession,
      Maritalstatus: patientData.Maritalstatus,
      Place_of_birth: patientData.Place_of_birth,
      TaxIDcode: patientData.TaxIDcode,
      has_children: patientData.Children?.has_children,
      children_gender: patientData.Children?.children_gender,
      have_brothers_sisters: patientData.Brother_sisters?.have_brothers_sisters,
      how_many_brothers_sisters:
        patientData.Brother_sisters?.how_many_brothers_sisters,
      type_of_delivery: patientData.Children?.type_of_delivery,
      pregnancy_complications: patientData.Children?.pregnancy_complications,
      isParentsAlive: patientData.Parents_alive?.isParentsAlive,
      who_is_alive: patientData.Parents_alive?.who_is_alive,
      are_you_pregnant: patientData.Children?.are_you_pregnant,
      week_of_gestation: patientData.Children?.week_of_gestation,
      ASL_assistance: patientData.ASL_assistance,
      General_Practitioner_Name: patientData.General_Practitioner_Name,
    },
  });
  const has_children = watch("has_children");
  const children_gender = watch("children_gender");
  const isParentsAlive = watch("isParentsAlive");
  const have_brothers_sisters = watch("have_brothers_sisters");
  const handlePersonalInfo = (data) => {
    data["PatientId"] = user.PatientId;
    data["isUpdated"] = true;
    data["Children"] = {
      has_children: has_children,
      children_gender: children_gender,
      type_of_delivery: data.type_of_delivery,
      pregnancy_complications: data.pregnancy_complications,
      are_you_pregnant: data.are_you_pregnant,
      week_of_gestation: data.week_of_gestation,
    };
    data["Brother_sisters"] = {
      have_brothers_sisters: have_brothers_sisters,
      how_many_brothers_sisters: data.how_many_brothers_sisters,
    };
    data["Parents_alive"] = {
      isParentsAlive: isParentsAlive,
      who_is_alive: data.who_is_alive,
    };
    delete data.has_children;
    delete data.children_gender;
    delete data.type_of_delivery;
    delete data.pregnancy_complications;
    delete data.are_you_pregnant;
    delete data.week_of_gestation;
    delete data.have_brothers_sisters;
    delete data.how_many_brothers_sisters;
    delete data.isParentsAlive;
    delete data.who_is_alive;
    dispatch(addPatientProfile(data));
  };

  useEffect(() => {
    if (msg) {
      toast.success(msg);
      reset();
    }
    if (error) {
      reset();
    }
  }, [msg]);

  return (
    <form onSubmit={handleSubmit(handlePersonalInfo)}>
      <div className="px-5">
        <Grid container columns={12} spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Name and surname</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("Name_Surname", {
                required: "name is required",
              })}
              disabled
            />
            {errors.Name && (
              <p className="text-red-500 text-xs mt-1">{errors.Name.message}</p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Date of Birth</label>
            <input
              type="date"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("DOB", {
                required: "date of birth is required",
              })}
              disabled={disabledFields}
            />
            {errors.DOB && (
              <p className="text-red-500 text-xs mt-1">{errors.DOB.message}</p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Place Of Birth</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("Place_of_birth", {
                required: "Enter your place of birth ??",
              })}
              disabled={disabledFields}
            />
            {errors.Place_of_birth && (
              <p className="text-red-500 text-xs mt-1">
                {errors.Place_of_birth.message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Tax ID code</label>
            <input
              value={patientData?.TaxIDcode}
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("TaxIDcode", {
                maxLength: {
                  value: 25,
                  message: "Tax ID code must be at most 25 characters long",
                },
                pattern: {
                  value: /^[a-zA-Z0-9]*$/,
                  message: "Tax ID code must be alphanumeric",
                },
              })}
              onInput={(e) => e.target.value = e.target.value.toUpperCase()}
              disabled={disabledFields}
            />
             {errors.TaxIDcode && (
              <p className="text-red-500 text-xs mt-1">
                {errors.TaxIDcode.message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Domicile</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("Domicile", {
                required: "domicile is required",
              })}
              disabled={disabledFields}
            />
            {errors.Domicile && (
              <p className="text-red-500 text-xs mt-1">
                {errors.Domicile.message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Residence</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("Residence", {
                required: "residence is required",
              })}
              disabled={disabledFields}
            />
            {errors.Residence && (
              <p className="text-red-500 text-xs mt-1">
                {errors.Residence.message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Mobile phone</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("Mobilephone", {
                required: "mobile number is required",
              })}
              disabled={disabledFields}
            />
            {errors.Mobilephone && (
              <p className="text-red-500 text-xs mt-1">
                {errors.Mobilephone.message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Mail</label>
            <input
              type="email"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("mail", {
                required: "email is required",
              })}
              disabled
            />
            {errors.mail && (
              <p className="text-red-500 text-xs mt-1">{errors.mail.message}</p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Marital status</label>
            <select
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("Maritalstatus")}
              disabled={disabledFields}
            >
              <option>Select</option>
              <option>Single</option>
              <option>Married</option>
            </select>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Children</label>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <AccordionHeader
                  fieldName="has_children"
                  register={register}
                  disabledFields={disabledFields}
                />
              </AccordionSummary>
              {has_children === "yes" && (
                <AccordionDetails>
                  <ChildrenSection
                    register={register}
                    disabledFields={disabledFields}
                    children_gender={watch("children_gender")}
                    are_you_pregnant={watch("are_you_pregnant")}
                  />
                </AccordionDetails>
              )}
            </Accordion>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Brothers or sisters</label>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <AccordionHeader
                  fieldName="have_brothers_sisters"
                  register={register}
                  disabledFields={disabledFields}
                />
              </AccordionSummary>
              {have_brothers_sisters === "yes" && (
                <AccordionDetails>
                  <>
                    <label className="text-sm mt-2 mb-1 block">
                      How many brothers or sisters
                    </label>
                    <input
                      type="text"
                      {...register("how_many_brothers_sisters")}
                      className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
                      disabled={disabledFields}
                    />
                  </>
                </AccordionDetails>
              )}
            </Accordion>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Parents alive</label>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <AccordionHeader
                  fieldName="isParentsAlive"
                  register={register}
                  disabledFields={disabledFields}
                />
              </AccordionSummary>
              {isParentsAlive === "yes" && (
                <AccordionDetails>
                  <ParentsSection
                    register={register}
                    disabledFields={disabledFields}
                  />
                </AccordionDetails>
              )}
            </Accordion>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Qualification</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("Qualification", {
                required: "qualification is required",
              })}
              disabled={disabledFields}
            />
            {errors.Qualification && (
              <p className="text-red-500 text-xs mt-1">
                {errors.Qualification.message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">Profession</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("Profession", {
                required: "profession is required",
              })}
              disabled={disabledFields}
            />
            {errors.Profession && (
              <p className="text-red-500 text-xs mt-1">
                {errors.Profession.message}
              </p>
            )}
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">ASL assistance</label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("ASL_assistance")}
              disabled={disabledFields}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <label className="text-sm mb-2 block">
              Name General Practitioner
            </label>
            <input
              type="text"
              className="border border-slate-400 px-3 h-11 rounded-xl w-full text-sm"
              {...register("General_Practitioner_Name")}
              disabled={disabledFields}
            />
          </Grid>
        </Grid>
      </div>
      {!patientData?.isUpdated && (
        <div className="flex gap-3 mt-4 justify-end border-t-2 pt-4 border-slate-300 px-5">
          <button
            type="button"
            className="rounded-full py-3 px-12 bg-red-200 text-sm text-red-700 hover:bg-red-500 hover:text-white transition-all delay-300"
            onClick={() => reset()}
          >
            Reset
          </button>
          <button
            type="submit"
            className="rounded-full py-3 px-12 bg-primary text-sm text-white hover:bg-black transition-all delay-300"
          >
            Save
          </button>
        </div>
      )}
      {isEditable === true && !disabledFields && (
        <div className="flex gap-3 mt-4 justify-end border-t-2 pt-4 border-slate-300 px-5">
          <button
            type="button"
            className="rounded-full py-3 px-12 bg-red-200 text-sm text-red-700 hover:bg-red-500 hover:text-white transition-all delay-300"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="rounded-full py-3 px-12 bg-primary text-sm text-white hover:bg-black transition-all delay-300"
          >
            Update
          </button>
        </div>
      )}
    </form>
  );
};
export default PersonalForm;
