import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
export default function Navbarheader() {
  const [isOpen, setIsOpen] = useState(false);
  const { token, user } = useSelector((state) => state.auth);
  const handleDashboardNavigation = user && token && user.userType === 'Patient' ? '/dashboard' : '/dr-dashboard';
  return (
    <>
      <nav className="bg-white">
        <div className="container mx-auto px-4 flex items-center justify-between py-4">
          <div className="flex items-center">
            <a href="/">
              <img src="/assets/images/logo.png" className="h-16" alt="logo" />
            </a>
          </div>
          <div className="hidden md:flex space-x-8 items-center">
            <Link to="/" className="text-primary font-bold">
              Home
            </Link>
            <Link to="/find-doctor" state={{name:"All"}} className="text-gray-700">
              Find Doctors
            </Link>
            <Link to="/who-we-are" className="text-gray-700">
              Who we are
            </Link>
            <Link to="/leadership" className="text-gray-700">
              Leadership
            </Link>
            {user === null && token === null ? (
              <>
                {" "}
                <Link to="/signin" className="text-primary">
                  <i class="fa-solid fa-arrow-right-to-bracket"></i> Sign In
                </Link>
                <Link
                  to="/register"
                  className="bg-primary text-white px-7 py-3 rounded-full transition-all duration-300 hover:bg-black "
                >
                  <i class="fa-solid fa-user"></i> Register Now
                </Link>
              </>
            ) : (
              <Link to={handleDashboardNavigation} >
                <i class="fa-solid fa-arrow-right-to-bracket"></i> Dashboard
              </Link>
            )}
          </div>
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-700 hover:text-gray-900 focus:outline-none"
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                />
              </svg>
            </button>
          </div>
        </div>
        {isOpen && (
          <div className="md:hidden absolute w-full  z-50 bg-white p-2">
            <Link to="/" className="block px-4 py-2 text-primary font-semibold">
              Home
            </Link>
            <Link to="/find-doctor" className="block px-4 py-2 text-gray-700">
              Find Doctors
            </Link>
            <Link to="/who-we-are" state={{name:"All"}} className="block px-4 py-2 text-gray-700">
              Who we are
            </Link>
            <Link to="/leadership" className="block px-4 py-2 text-gray-700">
              Leadership
            </Link>
            {user === null && token === null ? (
              <>
                <Link to="/signin" className="text-primary block px-4 py-2 ">
                  <i class="fa-solid fa-arrow-right-to-bracket"></i> Sign In
                </Link>
                <Link
                  to="/register"
                  className="bg-primary text-white px-7 py-3 rounded-full transition-all duration-300 hover:bg-black block"
                >
                  <i class="fa-solid fa-user"></i> Register Now
                </Link>
              </>
            ) : (
              <Link to={handleDashboardNavigation} className="block px-4 py-2 text-gray-700">
                <i class="fa-solid fa-arrow-right-to-bracket"></i> Dashboard
              </Link>
            )}
          </div>
        )}
      </nav>
    </>
  );
}
