import axios from "axios";
import { Constants } from "../Constants";
import {
  addPatientData,
  addPatientHealthRecord,
  clearError,
  clearMsg,
  setError,
  getPatientProfile,
  addToFavourites,
  removeFromFavourites,
  bookClinicAppointment,
  fetchAllAppointments,
  fetchAllTransactions,
} from "../Actions/Patient/userActions";
export const addPatientProfile = (userData) => async (dispatch, getState) => {
  dispatch(clearError());
  dispatch(clearMsg());
  let token = getState().auth.token;
  try {
    const response = await axios.post(
      `${Constants.addPatientProfile}`,
      userData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(addPatientData(response.data));
    setTimeout(() => {
      dispatch(clearMsg());
    }, 1000);
  } catch (error) {
    dispatch(setError(error.response.data.message));
  }
};
export const addPatientHealthProfile =
  (userData) => async (dispatch, getState) => {
    dispatch(clearError());
    dispatch(clearMsg());
    try {
      let token = getState().auth.token;
      const response = await axios.post(
        `${Constants.addPatientHealthProfile}`,
        userData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(addPatientHealthRecord(response.data));
      setTimeout(() => {
        dispatch(clearMsg());
      }, 1000);
    } catch (error) {
      dispatch(setError(error.response.data.message));
    }
  };


export const AddToFavourites = (userData) => async (dispatch, getState) => {
  dispatch(clearError());
  try {
    let token = getState().auth.token;
    const response = await axios.post(
      `${Constants.addToFavourites}`,
      userData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(addToFavourites(response.data));
    const { Favourites } = response.data;
    localStorage.setItem(
      "userInfo",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("userInfo")),
        Favourites,
      })
    );
    setTimeout(() => {
      dispatch(clearMsg());
    }, 1000);
  } catch (error) {
    dispatch(setError(error.response.data.message));
  }
};

export const RemoveFromFavourites =
  (userData) => async (dispatch, getState) => {
    dispatch(clearError());
    try {
      let token = getState().auth.token;
      const response = await axios.post(
        `${Constants.removeFromFavourites}`,
        userData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(removeFromFavourites(response.data));
      const { Favourites } = response.data;
      localStorage.setItem(
        "userInfo",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("userInfo")),
          Favourites,
        })
      );
      setTimeout(() => {
        dispatch(clearMsg());
      }, 1000);
      return response.data;
    } catch (error) {
      dispatch(setError(error.response.data.message));
    }
  };

export const GetPatientProfile = (userData) => async (dispatch, getState) => {
  dispatch(clearError());
  try {
    let token = getState().auth.token;
    const response = await axios.get(
      `${Constants.getPatientProfile}${userData}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(getPatientProfile(response.data));
  } catch (error) {
    return error
  }
};

export const FetchAllTransaction = (userData) => async (dispatch, getState) => {
  dispatch(clearError());
  try {
    let token = getState().auth.token;
    const response = await axios.get(`${Constants.fetchTransactions}${userData.Id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(fetchAllTransactions(response.data));
  } catch (error) {
    return error
  }
};

export const BookClinicAppointment =
  (userData) => async (dispatch, getState) => {
    dispatch(clearError());
    try {
      let token = getState().auth.token;
      const response = await axios.post(
        `${Constants.bookClinicAppointment}`,
        userData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(bookClinicAppointment(response.data));
    } catch (error) {
      dispatch(setError(error.response.data.message));
    }
  };

export const FetchAllAppointment = (userData) => async (dispatch, getState) => {
  try {
    let token = getState().auth.token;
    const response = await axios.get(
      `${Constants.fetchAllAppointment}${userData.Id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch(fetchAllAppointments(response.data.Appointments));
  } catch (error) {
    dispatch(setError(error.response.data.message));
  }
};
