import React, { useState, useEffect } from "react";
import BannerDashboard from "./BannerDashboard";
import { Grid } from "@mui/material";
import PersonalForm from "../Forms/PersonalForm";
import HealthForm from "../Forms/HealthForm";
import { FetchAllTransaction, GetPatientProfile } from "../../Thunk/patientThunks";
import { useSelector, useDispatch } from "react-redux";
import ToggleNavs from "../ToggleNavs/toggleNavs";
export default function Dashboard() {
  const [activeTab, setActiveTab] = useState("personal");
  const { user } = useSelector((state) => state.auth);
  const { msg, patientData } = useSelector((state) => state.user);
  const [healthFieldDisable, setHealthFieldDisable] = useState(false);
  const [personalFieldDisable, setPersonalFieldDisable] = useState(false);
  const [isPersonalEditing, setIsPersonalEditing] = useState(false);
  const [isHealthEditing, setIsHealthEditing] = useState(false);
  useEffect(() => {
    if (Object.keys(patientData).length > 0) {
      setHealthFieldDisable(patientData?.Health_Records?.isUpdated ?? false);
      setPersonalFieldDisable(patientData?.isUpdated ?? false);
    } else {
      setHealthFieldDisable(false);
      setPersonalFieldDisable(false);
    }
  }, [patientData]);

  const onEditHandler = () => {
    if (activeTab == "personal") {
      setPersonalFieldDisable(false);
      setIsPersonalEditing(true);
    }
    if (activeTab == "healthRecord") {
      setHealthFieldDisable(false);
      setIsHealthEditing(true);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetPatientProfile(user.PatientId));
  }, [msg]);



  return (
    <>
      <BannerDashboard pageTitle="Profile" />
      <section className="py-8 bg-slate-50">
        <div className="container mx-auto px-4">
          <Grid container columns={12} spacing={4}>
            <Grid item xs={12} md={4} lg={3}>
              <ToggleNavs />
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <h1 className="text-2xl pb-3 mb-5 font-semibold border-b-2 border-slate-300">
                Dashboard
              </h1>
              <div className="py-5 border-2 border-slate-300 rounded-2xl bg-white">
                <div className="flex justify-between items-center px-5">
                  <div className="flex space-x-4 border-b">
                    <button
                      type="button"
                      className={`py-2 px-4 ${
                        activeTab === "personal"
                          ? "text-primary border-b-2 border-blue-700 font-semibold"
                          : "text-gray-500 hover:text-black"
                      }`}
                      onClick={() => setActiveTab("personal")}
                    >
                      Personal
                    </button>
                    <button
                      className={`py-2 px-4 ${
                        activeTab === "healthRecord"
                          ? "text-primary border-b-2 border-blue-700 font-semibold"
                          : "text-gray-500 hover:text-black"
                      }`}
                      onClick={() => setActiveTab("healthRecord")}
                    >
                      Health Record
                    </button>
                  </div>
                  {(activeTab === "healthRecord" &&
                    patientData?.Health_Records?.isUpdated) ||
                  (activeTab === "personal" && patientData?.isUpdated) ? (
                    <div>
                      <a
                        onClick={onEditHandler}
                        className="text-sm hover:cursor-pointer"
                      >
                        <i className="fa-regular fa-pen-to-square"></i> Edit
                      </a>
                    </div>
                  ) : null}
                </div>

                <div className="mt-4">
                  {activeTab === "personal" && (
                    <PersonalForm
                      disabledFields={personalFieldDisable}
                      isEditable={isPersonalEditing}
                    />
                  )}
                  {activeTab === "healthRecord" && (
                    <HealthForm
                      disableCondition={healthFieldDisable}
                      isEditable={isHealthEditing}
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    </>
  );
}
