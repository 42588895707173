import {
    ADD_PATIENT_DATA,
    ADD_PATIENT_HEALTH_RECORD,
    GET_PATIENT_PROFILE,
    CLEAR_ERROR,
    CLEAR_MSG,
    SET_ERROR,
    ADD_TO_FAVOURITES,
    REMOVE_FROM_FAVOURITES,
    BOOK_CLINIC_APPOINTMENT,
    FETCH_ALL_APPOINTMENTS,
    GET_ALL_TRANSACTIONS,
  } from "../Actions/Patient/types";
  
  const initialState = {
    patientData: {},
    transactions: [],
    msg: null,
    appointments: [],
    error: null,
  };
  
  const userReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_PATIENT_DATA : 
        return {
          ...state,
          msg: action.payload.message,
        };
      case ADD_PATIENT_HEALTH_RECORD:
        return {
          ...state,
          msg: action.payload.message,
        };
      case GET_PATIENT_PROFILE:
        return {
          ...state,
          patientData: action.payload,
        };
      case GET_ALL_TRANSACTIONS:
        return {
          ...state,
          transactions: action.payload,
        }
      case ADD_TO_FAVOURITES: 
        return {
          ...state,
          msg: action.payload.message
        }
      case BOOK_CLINIC_APPOINTMENT:
         return {
            ...state,
            msg: action.payload.message
         }
      case REMOVE_FROM_FAVOURITES:
        return {
          ...state,
          msg: action.payload.message,
        }
      case FETCH_ALL_APPOINTMENTS:
        return {
          ...state,
          appointments: action.payload
        }
      case SET_ERROR:
        return {
          ...state,
          error: action.payload,
        };
      case CLEAR_ERROR:
        return {
          ...state,
          error: null,
        };
      case CLEAR_MSG:
        return {
            ...state,
            msg: null,
        };
      default:
        return state;
    }
  };
  
  export default userReducer;
  